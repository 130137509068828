import { phoneFormat } from '../base/_phone_format.js';

// APP SETTINGS
    var settings = document.querySelector('.settings');
    // set background color
    var body = document.querySelector('body');
//    body.style.backgroundColor = settings.getAttribute('data-background-color');

// VARIABLES

    var pickedImageH = 165;
    var pickedImageTop = 30;
    var pickedDateH = $('.selected-date').height();
    var roomCount = document.querySelector('.data-container').getAttribute('data-room-count');
    var slotItemHeight = document.getElementsByClassName('slot-item')[0].offsetHeight;
    var waiverTop = pickedImageTop + pickedImageH + pickedDateH + slotItemHeight;
    var windowWidth = document.body.clientWidth // without scroll bar


// FUNCTIONS

    // initialize jquery date picker
    var dateToday = new Date();
    var datePickerSettings = {
        "en": {
            prevText: 'Prev',
            nextText: 'Next',
            monthNames: ['January','February','March','April','May','June', 'July','August','September','October','November','December'],
            dayNamesMin: ['Su','Mo','Tu','We','Th','Fr','Sa'],
            dateFormat: 'm/d/yy',
        },
        "es": {
            prevText: 'Ant',
            nextText: 'Sig',
            monthNames: ['Enero','Febrero','Marzo','Abril','Mayo','Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
            dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
            dateFormat: 'yy-mm-dd',
        },
        "fr": {
            prevText: 'Préc',
            nextText: 'Suiv',
            monthNames: ['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Decembre'],
            dayNamesMin: ['Di','Lu','Ma','Me','Je','Ve','Sa'],
            dateFormat: 'dd-mm-yy',
        },
    }

    $.datepicker.setDefaults(datePickerSettings[window.language]);
    $( "#gameDatePicker" ).datepicker({
        minDate: dateToday,
        onSelect: function(dateText) {
            $('.selected-date').html(dateText);
            window.pickedDate = dateText
          }
    });

    // var currentDate = $("#gameDatePicker").datepicker("getDate", {dateFormat: 'm/d/yy'});
    // $('.selected-date').html($.datepicker.formatDate("m/d/yy", currentDate));

    function setWidthOfDatePicker(windowWidth) {
        var datePickerWidth = $('#gameDatePicker').width();
        var scale = 1;
        if ((datePickerWidth + 30) > windowWidth) {
            scale = windowWidth/(datePickerWidth + 30);
        }
        return scale;
    }


    function createHeadStyleTag() {
        var element  = document.createElement("style");
        var headerTextBreakPoint = 850;
        var headerTextFontSize = 62;
        var headerTextFontSize = headerTextFontSize*Math.min(1, window.innerWidth/headerTextBreakPoint);
        var gameMarginBreakPoint = (roomCount*300)+((roomCount-1)*40)+(50*2);
        var gameMarginOuter = 50;
        var gameMarginInner = 40;
        var gameMarginOuter = gameMarginOuter*Math.min(1, window.innerWidth/gameMarginBreakPoint);
        var gameMarginInner = gameMarginInner*Math.min(1, window.innerWidth/gameMarginBreakPoint);
        var slotsWidth = document.querySelector('.slots').getBoundingClientRect().width;
        var slotsCenter = (window.innerWidth/2) - (slotsWidth/2);
        element.id = "layout-variables";
        element.innerHTML = '.header-text {' +
                                'font-size: '+headerTextFontSize+'px;'+
                                'margin: '+(headerTextFontSize*.8)+'px 0;'+
                                'line-height: '+headerTextFontSize+'px;}'+
                            '.slots {'+
                                'top: '+(pickedImageTop+pickedImageH+pickedDateH)+'px;'+
                                'left: 50%;}'+
                            '.datepicker-container {'+
                                'top: '+(pickedImageTop+pickedImageH)+'px;'+
                                'left: 50%;}'+
                            '.calendar-container {'+
                                'transform: scale('+setWidthOfDatePicker(windowWidth)+');}'+
                            '.waiver {'+
                                'top: ' + waiverTop + 'px;}'
        var header = document.getElementsByTagName("HEAD")[0];
        header.appendChild(element);
    }

    // position picked image - update translate property
    function positionPickedImage(pickedGame) {
        var pickedGamePosition = pickedGame.getBoundingClientRect();
        var pickedImageW = pickedGamePosition.width * (pickedImageH/pickedGamePosition.height);
//        var pickedImageLeft = (window.innerWidth/2) - (pickedImageW/2);
        var pickedImageLeft = (windowWidth/2) - (pickedImageW/2);
        var pickedDeltaX = pickedImageLeft - pickedGamePosition.left;
        var scrollPosition = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        var pickedDeltaY = pickedImageTop - (pickedGamePosition.top + scrollPosition);
        var pickedDeltaScale = pickedImageW / pickedGamePosition.width;
        pickedGame.style.transform = 'translate('+pickedDeltaX+'px, '+pickedDeltaY+'px) scale('+pickedDeltaScale+')';
        $('html, body').animate({ scrollTop: 0 }, 400)
    }

    // detect transitionEnd events - should work with all browsers
    function whichTransitionEvent(el){
        var t;
        var transitions = {
          'transition':'transitionend',
          'OTransition':'oTransitionEnd',
          'MozTransition':'transitionend',
          'WebkitTransition':'webkitTransitionEnd'
        }

        for(t in transitions){
            if( el.style[t] !== undefined ){
                return transitions[t];
            }
        }
    }

    createHeadStyleTag();

// GAME VIEW

    // add click event to pick game
    var games = document.getElementsByClassName("js-pick-game");

    var pickGame = function() {
        var pickedGame = this;

        var dataRoomId = pickedGame.parentNode.getAttribute('data-room-id');
        document.querySelector('.data-container').setAttribute('data-room-id', dataRoomId);
        window.roomId = dataRoomId
        var dataRoomName = pickedGame.parentNode.getAttribute('data-room-name');
        document.querySelector('.data-container').setAttribute('data-room-name', dataRoomName);

        pickedGame.parentNode.classList.add('timeView');
        if (window.langs > 1) {
            var languages = document.getElementById('languages');
            languages.classList.add('timeView');
            setTimeout(function() {languages.classList.add('displayNone');}, 500);
        }
        // add timeViewSibling class to not picked games
        var siblings = [];
        var allChildren = pickedGame.parentNode.parentNode.children;
        for (i=0; i < allChildren.length; i++) {
            if (allChildren[i].classList.contains('game-image')) {
                // allChildren[i].querySelector('img').removeEventListener('click', pickGame, false);
                allChildren[i].querySelector('.js-pick-game').removeEventListener('click', pickGame, false);
                if (allChildren[i] != pickedGame.parentNode) allChildren[i].classList.add('timeViewSibling');
            }
        }

        // add translate style to picked image
        positionPickedImage(pickedGame.parentNode);

        var loopCount = pickedGame.parentNode.getAttribute('data-loop');
        // fade in background image
        const bgImageUrl = pickedGame.getAttribute('src');
        const bgImageElement = document.getElementById('bg-image-tag');
        bgImageElement.setAttribute('src', bgImageUrl);
        document.querySelector('#bg-image-container').classList.add('timeView');


        // fade in datepicker
        document.querySelector('.datepicker-container').classList.add('dateView');

        // add class op-1 to back button to make button visible
        document.querySelector('.back-button').classList.add('op-1');

        // remove will change property from background and game images
        var backgrounds = document.getElementsByClassName('backgrounds');
        for (i = 0; i < backgrounds.length; i++) {
            backgrounds[i].style.willChange = 'unset';
        }
        var gameImages = document.getElementsByClassName('game-image');
        for (i = 0; i < gameImages.length; i++) {
            gameImages[i].style.willChange = 'unset';
        }

    }

    for (var i = 0; i < games.length; i++) {
        games[i].addEventListener('click', pickGame, false);
    }


// DATE VIEW

    var pickDate = function() {
        // fade out date picker
        $('.datepicker-container').addClass('dateViewEnd');

        // position and fade in selected date
        $('.selected-date').addClass('dateChosen');
        $('.selected-date')[0].style.top = (pickedImageH + pickedImageTop + 5)+'px';

        // get slots for room on selected date
        var getSlotsData = {
            'room_id': window.roomId,
            'date': window.pickedDate,
        }

        $.ajax({
            url : $('.data-container').attr("data-get-slots-url"),
            type : "POST",
            data : getSlotsData,
            success : function(data) {
                $('#slots-container').html(data.html_string);
            },
            // handle a non-successful response
            error : function(xhr, errmsg, err) {

            }
        });

        // fade in slots
        document.querySelector('#slots-container').classList.add('timeView');

        // remove will-change property from slots element
        var slots = document.getElementsByClassName('slots');
        for (i = 0; i < slots.length; i++) {
            slots[i].style.willChange = 'unset';
        }

        // add will-change property to slots-text, slot-items and waiver
        var slotsText = document.getElementsByClassName('slots-text');
        for (i = 0; i < slotsText.length; i++) {
            slotsText[i].style.willChange = 'opacity';
        }
        var slotItem = document.getElementsByClassName('slot-item');
        for (i = 0; i < slotItem.length; i++) {
            slotItem[i].style.willChange = 'transform, opacity';
        }
        document.querySelector('.waiver').style.willChange = 'transform';

        $('html, body').animate({ scrollTop: 0 }, 400)
    }

    $('.datepicker-submit-button').click(pickDate);

// TIME VIEW

    var pickTime = function() {
        // select picked time
        var pickedTime = this.parentNode;
        // set slot id in data container
        var dataSlotId = pickedTime.getAttribute('data-slot-id');
        var dataSlotTime = pickedTime.getAttribute('data-slot-time');
        window.slotId = dataSlotId
        window.slotTime = dataSlotTime
        document.querySelector('.data-container').setAttribute('data-slot-id', dataSlotId);
        document.querySelector('.data-container').setAttribute('data-slot-time', dataSlotTime);
        // add waiverView class to picked time and waiver
        pickedTime.classList.add('waiverView');
        document.querySelector('.waiver').classList.add('waiverView');
        // add waiverViewSibling class to not picked times and slot text and remove eventListener
        var allChildren = pickedTime.parentNode.children;
        for (i=0; i < allChildren.length; i++) {
            if (allChildren[i].classList.contains('slots-text')) {
                allChildren[i].classList.add('waiverViewSibling');
            }
            if (allChildren[i].classList.contains('slot-item')) {
                allChildren[i].querySelector('span').removeEventListener('click', pickTime, false);
                if (allChildren[i] != pickedTime) allChildren[i].classList.add('waiverViewSibling');
            }
        }
        // add transform: translate to picked slot item
        var pickedTimeTop = pickedTime.getBoundingClientRect().top + (window.scrollY || window.pageYOffset);
        var translateY = pickedTimeTop - (pickedImageH + pickedImageTop + pickedDateH);
        pickedTime.style.transform = 'translateY(-'+translateY+'px)';

        // remove will-change property from slots-text, slot-items and waiver
        var slotsText = document.getElementsByClassName('slots-text');
        for (i = 0; i < slotsText.length; i++) {
            slotsText[i].style.willChange = 'unset';
        }
        var slotItem = document.getElementsByClassName('slot-item');
        for (i = 0; i < slotItem.length; i++) {
            slotItem[i].style.willChange = 'unset';
        }
        document.querySelector('.waiver').style.willChange = 'unset';

        // add room name and slot time to slide down header
        var room_name = document.querySelector('.data-container').getAttribute('data-room-name');
        var date = window.pickedDate
        var slot_time = document.querySelector('.slot-item.waiverView .js-time-click').innerHTML;
        document.querySelector('.slide-down-header').innerHTML = date + ' @ ' + slot_time;

        $('html, body').animate({ scrollTop: 0 }, 400)
    }

    // TRIGGER - PICK TIME
    $('#slots-container').on('click', '.js-time-click', pickTime)




// SUBMIT WAIVER FORM AFTER SIGNATURE

    $(document).on('click', '#sig-submit', function(){
        $(this).attr("disabled", "disabled");
        var inputs = document.querySelectorAll('.waiver-form-field');
        var extract_sig = $('#signature').jSignature("getData", "default")
        var input_dict = {};

        for (var i = 0; i < inputs.length; ++i) {
            if (inputs.item(i).name == 'opt_in_checkbox') {
                var check_box_id = '#' + inputs.item(i).id
                input_dict[inputs.item(i).name] = $(check_box_id).is(":checked");
            } else if (inputs.item(i).name == 'email_waiver_option') {
                var check_box_id = '#' + inputs.item(i).id
                input_dict[inputs.item(i).name] = $(check_box_id).is(":checked");
            } else if (inputs.item(i).name == 'is_participating') {
                let value = document.querySelector('#' + inputs.item(i).id).value
                const minor_count = document.querySelectorAll('.minor-element').length
                if (minor_count === 0) value = 'true'
                input_dict[inputs.item(i).name] = value
            } else if (inputs.item(i).name == 'first_name' || inputs.item(i).name == 'last_name') {
                input_dict[inputs.item(i).name] = inputs.item(i).value.trim();
            } else if (inputs.item(i).name == 'email') {
                input_dict[inputs.item(i).name] = inputs.item(i).value.trim().toLowerCase();
            } else if (inputs.item(i).name == 'dob' && inputs.item(i).value !== '') {
                console.log(inputs.item(i).value)
                var formattedDOB = moment(inputs.item(i).value, "MM/DD/YYYY").format('YYYY-MM-DD');
                input_dict[inputs.item(i).name] = formattedDOB;
            } else if (inputs.item(i).name == 'phone') {
                if (inputs.item(i).value == "") {
                    var formattedPhone = "";
                } else {
                    var formattedPhone = inputs.item(i).value.replace(/[^0-9\+]/g, '');
                }
                input_dict[inputs.item(i).name] = formattedPhone;
            } else if (inputs.item(i).name == 'team_name') {
                input_dict[inputs.item(i).name] = inputs.item(i).value.trim();
            } else if (inputs.item(i).name.substring(0, 6) == 'custom') {
                var customValue = inputs.item(i).name
                var customName = inputs.item(i).getAttribute('data-field-name');
                if (inputs.item(i).name.substring(0, 12) == 'custom_check') {
                    var check_box_id = '#' + inputs.item(i).id
                    input_dict[customValue] = $(check_box_id).is(":checked");
                } else {
                    input_dict[customValue] = inputs.item(i).value;
                }
                input_dict[customValue + "_name"] = customName;
            } else {
                input_dict[inputs.item(i).name] = inputs.item(i).value;
            }
        }
        
        input_dict["sig"] = extract_sig;
//        input_dict["sig_format"] = extract_sig[0];
        input_dict["room_id"] = window.roomId;
        input_dict["slot_id"] = window.slotId
        input_dict["slot_time"] = window.slotTime
        input_dict["date"] = window.pickedDate
        // Variables needed only for SignedWaiver
        input_dict["signed_game"] = $('.data-container').attr("data-room-name");
        input_dict["signed_time"] = $('.slot-item.waiverView > span').text();
        input_dict["signed_waiver_content"] = $('.waiver-text-container').html();
        input_dict["signed_waiver_header"] = $('.waiver-header').text();
        input_dict["signed_waiver_id"] = $('.data-container').attr("data-waiver-id");

        waiver_form_submit(input_dict);
    });

    $('input[name="phone"]').each(function(){
        $(this).on("keyup paste", function (e) {
          var output,
            $this = $(this),
            input = $this.val();
      
          if(e.keyCode != 8) {
            $this.val(phoneFormat(input, window.phoneFormat));
          }
        });
      });

    $('input[name="dob"]').each(function(){
        $(this).on("keyup paste", function (e) {
          var output,
            $this = $(this),
            input = $this.val();
      
          if(e.keyCode != 8) {
            input = input.replace(/[^0-9]/g, '');
            var month = input.substr(0, 2);
            var day = input.substr(2, 2);
            var year = input.substr(4, 4);
            if (month.length < 2) {
              output = month;
            } else if (month.length == 2 && day.length < 2) {
              output = month + "/" + day;
            } else if (month.length == 2 && day.length == 2) {
              output = month + "/" + day + "/" + year;
            }
            $this.val(output);
          }
        });
      });
    
    // AJAX for submitting waiver form
    function waiver_form_submit(input_dict) {
        $.ajax({
            url : $('#sig-submit').attr("data-url"),
            type : "POST", // http method
            data : input_dict,
            // handle a successful response
            success : function(data) {
                console.log("success");
                window.location = $('#sig-submit').attr("data-redirect");
            },
            // handle a non-successful response
            error : function(xhr, errmsg, err) {
                $(this).removeAttr("disabled");
            }
        });
    };


// ADD MINOR

    $(document).on('click', '#add-minor-button', function(){
        const minor_count = document.querySelectorAll('.minor-element').length + 1
        var minorText = {
            "en": "MINOR ",
            "es": "MENOR ",
            "fr": "MINEUR ",
        }

        var new_minor = '\
            <div class="form-group pt-0 minor-element">\
                <div class="">\
                    <label class="" for="id_minor_' + minor_count + '">' + minorText[window.language] + minor_count + '</label>\
                    <div class="row">\
                        <div class="col-12 minor-row">\
                            <input type="minor" name="minor_' + minor_count + '" class="form-control waiver-form-field" id="id_minor_' + minor_count + '">\
                            <a class="delete-minor" style="cursor:pointer;">\
                                <i class="fas fa-times mx-auto"></i>\
                            </a>\
                        </div>\
                    </div>\
                </div>\
            </div>\
        ';

        $('.minor-container .delete-minor').addClass('visibility-hidden');
        $('.minor-container').append(new_minor);
        const isParticipatingCheckbox = document.getElementById('is-participating-select')
        if (minor_count === 1) {
            isParticipatingCheckbox.style.display = 'block'
        }
    });

// DELETE MINOR

    $(document).on('click', '.delete-minor', function(){
        const minor_count = document.querySelectorAll('.minor-element').length
        $(this).closest('.minor-element').remove();
        $('.minor-container .delete-minor:last').removeClass('visibility-hidden');
        if (minor_count === 1) {
            const isParticipatingCheckbox = document.getElementById('is-participating-select')
            isParticipatingCheckbox.style.display = 'none'
        }
    });


// MAKE STICKY HEADER

    window.onscroll = function() {stickyH()};

    function stickyH() {
        var header = $('.slide-down-header')
        if ($('.slot-item.waiverView').length) {
            var trigger = $('.slot-item.waiverView').offset().top
            if (window.pageYOffset > trigger) {
                header.addClass("sticky");
            } else {
                header.removeClass("sticky");
            }
        }
    };


// REFRESH PAGE WITH BACK BUTTON

    $(document).on('click', '.js-refresh', function(){
        location.reload();
    });













// CSRF token handling below

    // This function gets cookie with a given name
    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie != '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = jQuery.trim(cookies[i]);
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) == (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    var csrftoken = getCookie('csrftoken');

    /*
    The functions below will create a header with csrftoken
    */

    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }
    function sameOrigin(url) {
        // test that a given url is a same-origin URL
        // url could be relative or scheme relative or absolute
        var host = document.location.host; // host + port
        var protocol = document.location.protocol;
        var sr_origin = '//' + host;
        var origin = protocol + sr_origin;
        // Allow absolute or scheme relative URLs to same origin
        return (url == origin || url.slice(0, origin.length + 1) == origin + '/') ||
            (url == sr_origin || url.slice(0, sr_origin.length + 1) == sr_origin + '/') ||
            // or any other URL that isn't scheme relative or absolute i.e relative.
            !(/^(\/\/|http:|https:).*/.test(url));
    }

    $.ajaxSetup({
        beforeSend: function(xhr, settings) {
//            if (!csrfSafeMethod(settings.type) && sameOrigin(settings.url)) {
            if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                // Send the token to same-origin, relative URLs only.
                // Send the token only if the method warrants CSRF protection
                // Using the CSRFToken value acquired earlier
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
            }
        }
    });




